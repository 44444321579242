import React from 'react';

import { useFormikContext } from 'formik';

import Select, { SelectProps, useOptions } from '../Select';

import { MemberSearchFormValues } from '@domain/clubs';

import { useFetchClubSelectOptions } from '@repositories/memberSearch';

import { getClubDisplayName, getClubValue } from '@utils/club-name';

import { useTranslation } from '@external/react-i18next';
import { ClubTypeFilterValue } from '@typings/graphql';

export type SelectTerminationReasonProps = Omit<SelectProps, 'options'> & {
  clubType: string;
};

export const useClubOptions: useOptions = (
  input: string,
  riDistrictId?: number,
  clubType?: ClubTypeFilterValue | null | string
) => {
  const { values } = useFormikContext<MemberSearchFormValues>();
  const { t } = useTranslation();
  const [clubId] =
    values.club && !values.district ? values.club.split('-') : [''];

  const { data, loading, error } = useFetchClubSelectOptions(
    input,
    riDistrictId,
    clubId,
    clubType as ClubTypeFilterValue | null
  );

  const options = data?.clubs.results?.map(club => ({
    label: getClubDisplayName(club.fullName, club.type, t),
    value: getClubValue(club.riClubId, club.type),
  }));

  return {
    options,
    loading,
    error: error?.message,
  };
};

export const ClubSelect: React.FC<Omit<SelectProps, 'options'>> = props => {
  const { t } = useTranslation();
  return (
    <Select
      {...props}
      placeholder={t('club-select.find-by-club-name', 'Find by club name')}
      options={useClubOptions}
      // react-select filters options by full match with option.value
      // since we use external search we don't need to filter options within react-select
      filterOption={() => true}
    />
  );
};
