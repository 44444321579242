import React from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from '@components/Button';
import { ClubSelect } from '@components/Formik/Select/ClubSelect';

import { CustomLabel } from './CustomLabel';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import {
  ClubTypeFilterValue,
  GetBiReportOrgOptionsQuery,
} from '@typings/operations';

interface Props {
  oktaId: string;
  reportTechnicalName: string;
  options: GetBiReportOrgOptionsQuery['options'];
}

const validationSchema = Yup.object().shape({
  selectedOption: Yup.string().required('Please select an option'),
});

interface FormValues {
  selectedOption: string;
  clubs?: { [key: string]: string };
}

const DataSelectorClubOptions: React.FC<Props> = ({
  oktaId,
  reportTechnicalName,
  options,
}) => {
  const { t } = useTranslation();

  const handleSubmit = async (values: FormValues) => {
    const { selectedOption, clubs } = values;
    const selectedClubId = clubs?.[selectedOption]
      ? clubs[selectedOption].split('-')[0]
      : selectedOption.split('-')[0];

    localizedNavigate(
      `/club-and-district-reports/${oktaId}/${reportTechnicalName}/club?clubId=${selectedClubId}`
    );
  };

  const clubType = ClubTypeFilterValue.RotaryClub;

  return (
    <Formik
      initialValues={{ selectedOption: '', clubs: {} }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }) => (
        <Form className="">
          {options.map((option, index) => {
            const uniqueId = `${option.roleName.replace(/\s+/g, '-')}-${index}`;
            const uniqueValue = `${option.clubId}-${index}`;
            const fieldId = `radio-${uniqueValue}`;
            const isSelected = values.selectedOption === uniqueValue;
            return (
              <div key={uniqueId}>
                <div className="flex flex-col py-7 border-b border-gray-350 relative">
                  <label
                    className="flex w-full cursor-pointer relative"
                    htmlFor={fieldId}
                  >
                    <Field
                      id={fieldId}
                      type="radio"
                      name="selectedOption"
                      value={uniqueValue}
                      checked={isSelected}
                      onChange={() => {
                        setFieldValue('selectedOption', uniqueValue);
                        setFieldValue('clubs', {}); // Reset the dropdown if any
                      }}
                      className="w-0"
                    />
                    <div className="w-full">
                      <div className="RadioLabel">
                        <div className="pl-5px -mt-3px">
                          <CustomLabel data={option} />
                        </div>
                      </div>
                    </div>
                  </label>
                  {option?.matchType === 'district' && (
                    <div className="w-4/5 mobile-l:w-250 desktop:w-1/3 tablet:ml-8 tablet:-mt-4 absolute tablet:static left-31 tablet:left-0 bottom-20 tablet:bottom-0">
                      <ClubSelect
                        name={`clubs.${uniqueValue}`}
                        clearable
                        label={t('search.members.filters-club-label', 'Club')}
                        riDistrictId={
                          isSelected ? Number(option.districtId) : Number('')
                        }
                        clubType={clubType}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <Button
            type="submit"
            className="mt-12 p-4 rounded-md text-white disabled:bg-gray-300 bg-bright-blue-600 text-sm w-230 max-w-230 min-w-230 h-50px max-h-50px min-h-50px"
            disabled={values.selectedOption === ''}
          >
            {t('continue', 'Continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default DataSelectorClubOptions;
